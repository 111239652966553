/** @format */

import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import AsyncSelect from "react-select/async";
import Arrow from "./../../../../Assets/icons/down-arrow.png";
import Cookies from "js-cookie";
import cloudIcon from "../../../../Assets/icons/cloud-add.png";

const PerRequestForm = () => {
  const [departments, setDepartments] = useState([]);
  const [selectDepartment, setSelectDepartment] = useState("");
  const [SupMName, setSupMName] = useState("Supply Manager");
  const [reqDate, setReqDate] = useState(new Date());
  const [dueDate, setDueDate] = useState("");
  const [reqTitle, setReqTitle] = useState("");
  const { REACT_APP_API_ENDPOINT } = process.env;
  const [selectedItem, setSelectedItem] = useState(null);
  const [itemArrow, setItemArrow] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [adjustedQty, setReqQty] = useState([]);
  const userId = Cookies.get("employee_no");
  const currentDate = new Date(); // Get the current date and time
  const formattedDate = currentDate.toISOString().split("T")[0];
  const [imagePreview, setImagePreview] = useState(null);
  const [image, setImage] = useState(null);
  const [imagePreviewsecond, setImagePreviewsecond] = useState(null);
  const [imagesecond, setImagesecond] = useState(null);
  const [imagePreviewthired, setImagePreviewthired] = useState(null);
  const [imagethired, setImagethired] = useState(null);

  useEffect(() => {
    const fetchDesignations = async () => {
      try {
        const response = await fetch(
          `${REACT_APP_API_ENDPOINT}/v1/hris/designations/getdesignation`
        );
        const data = await response.json();

        const uniqueDepartments = [
          ...new Set(data.map((item) => item.department)),
        ];
        setDepartments(uniqueDepartments);
      } catch (error) {
        console.error("Error fetching designations:", error);
      }
    };

    fetchDesignations();
  }, []);

  const handleItemSearch = async (inputValue) => {
    if (inputValue !== "") {
      try {
        const response = await fetch(
          `${
            process.env.REACT_APP_API_ENDPOINT
          }/v1/inventory/item/search?search=${encodeURIComponent(inputValue)}`
        );

        if (!response.ok) {
          throw new Error(`Error fetching data: ${response.statusText}`);
        }

        const data = await response.json();
        console.log("Get items:", data);

        // Extract relevant data from response to populate options
        const options = data.items.map((item) => ({
          value: item.item_id,
          label: `${item.item_id} - ${item.item_name}`, // Combining inventory_batch_id and item_name
          item_measure_unit: item.item_measure_unit,
          item_category_name: item.item_category_name,
          item_sub_category_name: item.item_sub_category_name,
          total_shadow_qty: item.total_shadow_qty,
          item_name: item.item_name,
          item_qty: 0,
          item_description: "",
        }));

        return options;
      } catch (error) {
        console.error(error);
      }
    } else {
      return [];
    }
  };

  const handleItemSelect = (selectedOption) => {
    setSelectedItem(selectedOption);

    // Check if the item already exists in the tableData
    const itemExists = tableData.some(
      (item) => item.value === selectedOption.value
    );

    if (!itemExists) {
      // Add selected item to tableData
      setTableData((prevTableData) => [
        ...prevTableData,
        {
          item_name: selectedOption.item_name,
          item_id: selectedOption.value,
          item_category_name: selectedOption.item_category_name,
          item_sub_category_name: selectedOption.item_sub_category_name,
          total_shadow_qty: selectedOption.total_shadow_qty,
          item_measure_unit: selectedOption.item_measure_unit,
          item_qty: 0, // Default value; should be updated based on actual available qty
          item_description: "",
        },
      ]);
    }
  };

  const handleSubmit = async () => {
    try {
      const formData = new FormData();

      // Add text data to the formData
      formData.append("title", reqTitle);
      formData.append("department_name", selectDepartment);
      formData.append("pr_date", reqDate.toLocaleDateString("en-GB"));
      formData.append("required_date", dueDate.toLocaleDateString("en-GB"));
      formData.append("pr_user_id", userId);
      formData.append("ordered_user_id", userId);
      // formData.append("approved_user_id", userId);
      // formData.append("prepared_user_id", userId);
      formData.append("ordered_date", formattedDate);
      // formData.append("approved_date", formattedDate);

      // Extract item_id and item_qty from tableData and add it to the formData
      const prItems = tableData.map((item) => ({
        item_id: item.item_id,
        item_qty: item.item_qty,
        item_description: item.item_description,
      }));
      formData.append("pr_items", JSON.stringify(prItems));

      // Add files to the formData
      formData.append("ordered_img", image); // Append file directly
      // formData.append("approved_img", imagesecond);
      // formData.append("prepared_img", imagethired);

      // Inspect the FormData content
      for (let pair of formData.entries()) {
        console.log(`${pair[0]}: ${pair[1]}`);
      }

      // Make the POST request using Axios
      const response = await axios.post(
        `${REACT_APP_API_ENDPOINT}/v1/supplyChain/purchaseRequest/add`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      // Handle the response
      console.log("Response:", response.data);
      alert("Purchase Request successfully added");
      handleReset();
    } catch (error) {
      // Handle errors
      console.error("Error:", error);
      alert("Failed to add Purchase Request");
    }
  };

  // Reset function
  const handleReset = () => {
    setReqTitle("");
    setReqDate("");
    setDueDate("");
    setSelectDepartment("");
    setTableData([]);
    setImagePreview(null);
    setImagePreviewsecond(null);
    setImagePreviewthired(null);
    setSelectedItem("");
  };

  const customStylesItems = {
    control: (baseStyles, state) => ({
      // class attribute : class=" css-i32vvf-control"
      ...baseStyles,
      background: "transparent",
      borderRadius: "100px",
      width: "900px",
      borderColor: state.isFocused ? "red" : "blacks",
      border: state.isFocused ? "none" : "none",
    }),
    valueContainer: (provided) => ({
      // class attribute : class=" css-i32vvf-valueContainer"
      ...provided,
      background: "transparent",
    }),
    input: (provided) => ({
      // class attribute : class=" css-i32vvf-input"
      ...provided,
      background: "transparent",
      placeholder: "item name",
      color: "white",
    }),
    singleValue: (provided) => ({
      // class attribute : class=" css-i32vvf-singleValue"
      ...provided,
      background: "transparent",
    }),
    indicatorSeparator: (provided) => ({
      // class attribute : class=" css-i32vvf-indicatorSeparator"
      ...provided,
      background: "transparent",

      borderRadius: "100px",
    }),
    dropdownIndicator: (provided) => ({
      // class attribute : class=" css-i32vvf-dropdownIndicator"
      ...provided,
      background: "transparent",
      display: "none",
    }),

    menu: (provided) => ({
      // 'menu' is from the div class too.
      ...provided,
      background: "white",
      width: "122%",
      position: "absolute",
      zIndex: "100",
      top: "30px",
      left: "-160px",
      borderRadius: "20px",
      overflow: "hidden",
    }),
    Option: (provided) => ({
      ...provided,
      background: "red",
    }),
  };

  //First Signature Box
  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    setImage(file);
    const reader = new FileReader();

    reader.onload = () => {
      setImagePreview(reader.result);
    };

    reader.readAsDataURL(file);
  };

  // Signature Box
  const handleDragOversecond = (e) => {
    e.preventDefault();
  };

  const handleDropsecond = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    setImagesecond(file);
    const reader = new FileReader();

    reader.onload = () => {
      setImagePreviewsecond(reader.result);
    };

    reader.readAsDataURL(file);
  };

  //Theired Signature Box
  const handleDragOverthired = (e) => {
    e.preventDefault();
  };

  const handleDropthired = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    setImagethired(file);
    const reader = new FileReader();

    reader.onload = () => {
      setImagePreviewthired(reader.result);
    };

    reader.readAsDataURL(file);
  };

  return (
    <div className="bg-background overflow-y-auto h-screen relative">
      <div>
        <p className="text-[40px] font-sans ml-6 mt-[1%] font-bold text-[#071C50] ">
          Purchase Requests Form
        </p>
      </div>
      <div className="w-full flex items-center justify-center">
        <div className="bg-[#F3F8FF] rounded-[46px] border border-[#00000036] px-[200px] py-11 my-10 flex flex-col items-center justify-center">
          <p className="header-1 text-center">Create Purchase Request</p>
          <div className="w-[600px] flex items-center justify-between my-10">
            <label className="label-1">Request Title</label>
            <label className="label-1">:</label>
            <input
              className="input-1 border border-[#00000036]"
              type="text"
              value={reqTitle}
              onChange={(e) => setReqTitle(e.target.value)}
            />
          </div>
          <div className="w-[600px] flex items-center justify-between my-10">
            <label className="label-1">From</label>
            <label className="label-1">:</label>
            <select
              value={selectDepartment}
              onChange={(e) => setSelectDepartment(e.target.value)}
              className="input-1 border border-[#00000036]"
            >
              <option>Select the Department</option>
              {departments.map((department, index) => (
                <option key={index} value={department}>
                  {department}
                </option>
              ))}
            </select>
          </div>
          <div className="w-[600px] flex items-center justify-between my-10">
            <label className="label-1">To</label>
            <label className="label-1">:</label>
            <input
              className="input-1 border border-[#00000036]"
              type="text"
              value={SupMName}
              disabled
            />
          </div>
          <div className="w-[600px] flex items-center justify-between my-10">
            <label className="label-1">Request Date</label>
            <label className="label-1">:</label>
            <DatePicker
              className="input-1 border border-[#00000036]"
              placeholderText="Enter Requested Date"
              selected={reqDate}
              onChange={(date) => setReqDate(date)}
            />
          </div>
          <div className="w-[600px] flex items-center justify-between my-10">
            <label className="label-1">Due Date</label>
            <label className="label-1">:</label>
            <DatePicker
              className="input-1 border border-[#00000036]"
              placeholderText="Enter Due Date"
              selected={dueDate}
              onChange={(date) => setDueDate(date)}
            />
          </div>
        </div>
      </div>

      <p className="header-1 mb-10">Purchase Request Item Details</p>

      <div className="w-full flex items-center justify-center px-12">
        <div className="bg-PrimaryColor1 w-full h-[82px] rounded-[100px] mx-20 flex items-center justify-between">
          <p className="label-1 ml-10 text-white">Item Name :</p>
          <AsyncSelect
            styles={customStylesItems}
            cacheOptions
            loadOptions={handleItemSearch}
            onMenuOpen={() => setItemArrow(true)}
            onMenuClose={() => setItemArrow(false)}
            onChange={handleItemSelect}
            value={selectedItem}
          />
          <img
            src={Arrow}
            alt="arrow"
            className={
              itemArrow
                ? "mr-7 size-6 transition duration-500 invert"
                : "mr-7 size-6 transition rotate-180 duration-500 invert"
            }
          />
        </div>
      </div>

      <div className="flex flex-col justify-start  bg-[#F3F8FF] rounded-[46px] border border-[#00000036] mt-10">
        <div className="w-full h-[86px] flex items-center justify-around bg-[#BFD8AE] rounded-[46px] mr-5">
          <p className="w-10 p1 text-[#656565] text-center">#</p>
          <div className="w-[350px] flex flex-col items-center">
            <p className="w-full p1 text-[#656565] text-center "> Item Name</p>
            <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
          </div>
          <div className="w-[200px] flex flex-col items-center">
            <p className="w-full p1 text-[#656565] text-center ">
              Available Qty
            </p>
            <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
          </div>
          <div className="w-[200px] flex flex-col items-center">
            <p className="w-full p1 text-[#656565] text-center ">
              Request Quantity
            </p>
            <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
          </div>
          <div className="w-[200px] flex flex-col items-center">
            <p className="w-full p1 text-[#656565] text-center ">Description</p>
            <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
          </div>
          <div className="w-[200px] flex flex-col items-center">
            <p className="w-full p1 text-[#656565] text-center ">
              Item Category
            </p>
            <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
          </div>
          <div className="w-[200px] flex flex-col items-center">
            <p className="w-full p1 text-[#656565] text-center ">
              Item Sub Category
            </p>
            <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
          </div>
          <div className="w-[200px] flex flex-col items-center">
            <p className="w-full p1 text-[#656565] text-center ">
              Item Measure Unit
            </p>
            <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
          </div>
          <div className="w-[200px] flex flex-col items-center">
            <p className="w-full p1 text-[#656565] text-center ">Action</p>
            <div className="border-[3px] border-[#656565] w-[67px] rounded-[100px] mt-1"></div>
          </div>
        </div>
        <div className="flex flex-col gap-y-5 my-3 h-[250px] overflow-y-scroll">
          {tableData.length > 0
            ? tableData.map((item, index) => (
                <div key={index} className="w-full  flex justify-around ">
                  <div className="w-10 flex text-center">
                    <p className="w-full p1 text-[#656565] text-center ">
                      {index + 1}
                    </p>
                  </div>
                  <div className="w-[350px] flex  text-center">
                    <p className="w-full p1 text-[#656565] text-center ">
                      {item.item_name}
                    </p>
                  </div>
                  <div className="w-[200px] flex  text-center">
                    <p className="w-full p1 text-[#656565] text-center ">
                      {item.total_shadow_qty}
                    </p>
                  </div>
                  <div className="w-[200px] flex text-center">
                    <p className="w-full p1 text-[#656565] text-center ">
                      <input
                        type="number"
                        className="w-full border border-[#797979] rounded-[40px] px-[10px]"
                        value={item.item_qty}
                        onChange={(e) => {
                          let value = e.target.value;

                          let temp = [...tableData];
                          temp[index].item_qty = value;
                          setTableData(temp);
                        }}
                      />
                    </p>
                  </div>
                  <div className="w-[200px] flex  text-center">
                    <p className="w-full p1 ml-5 text-[#656565] text-center ">
                      <textarea
                        className="w-full border border-[#797979] rounded-[10px] px-[10px]"
                        value={item.item_description}
                        onChange={(e) => {
                          const temp = [...tableData];
                          temp[index].item_description = e.target.value;
                          setTableData(temp);
                        }}
                      />
                    </p>
                  </div>
                  <div className="w-[200px] flex  text-center">
                    <p className="w-full p1 text-[#656565] text-center ">
                      {item.item_category_name}
                    </p>
                  </div>
                  <div className="w-[200px] flex text-center">
                    <p className="w-full p1 text-[#656565] text-center ">
                      {item.item_sub_category_name}
                    </p>
                  </div>

                  <div className="w-[200px] flex  text-center">
                    <p className="w-full p1 text-[#656565] text-center ">
                      {item.item_measure_unit}
                    </p>
                  </div>

                  <div className="w-[200px] flex justify-center text-center">
                    <button
                      className="w-[full] h-[45px] flex items-center justify-center bg-[#912020] border rounded-[23px] px-4"
                      onClick={() => {
                        let temp = [...tableData];
                        temp.splice(index, 1);
                        setTableData(temp);
                      }}
                    >
                      <p className="p2 text-white">delete</p>
                    </button>
                  </div>
                </div>
              ))
            : null}
        </div>
      </div>

      <div className="  grid grid-cols-1 ml-6 ">
        {/* Drag and drop area */}
        <div
          className="flex flex-col items-center justify-center mt-6"
          onDragOver={handleDragOver}
          onDrop={handleDrop}
        >
          {imagePreview ? (
            <img
              src={imagePreview}
              alt="Preview"
              className="w-40 h-40 rounded-lg mb-4"
            />
          ) : (
            <div className="bg-white border border-gray-300 w-50 h-60 rounded-lg flex flex-col items-center justify-center mb-6">
              <p className="text-black font-bold ml-2 mt-2 text-[24px]">
                Ordered By
              </p>
              <img
                src={cloudIcon}
                alt="Cloud Icon"
                className="w-16 h-16 mb-2"
              />
              <p className="text-[#8691AB] font-bold ml-4">
                Drag and Drop Digital Signature
              </p>
            </div>
          )}
        </div>

        {/* Second Signature */}
        {/* <div
          className="flex flex-col items-center justify-center mt-6"
          onDragOver={handleDragOversecond}
          onDrop={handleDropsecond}
        >
          {imagePreviewsecond ? (
            <img
              src={imagePreviewsecond}
              alt="Preview"
              className="w-40 h-40 rounded-lg mb-4"
            />
          ) : (
            <div className="bg-white border border-gray-300 w-50 h-60 rounded-lg flex flex-col items-center justify-center mb-6">
              <p className="text-black font-bold ml-2 mt-2 text-[24px]">
                Approved By
              </p>
              <img
                src={cloudIcon}
                alt="Cloud Icon"
                className="w-16 h-16 mb-2"
              />
              <p className="text-[#8691AB] font-bold ml-4">
                Drag and Drop Digital Signature
              </p>
            </div>
          )}
        </div> */}

        {/* Thiered Signature */}
        {/* <div
          className="flex flex-col items-center justify-center mt-6"
          onDragOver={handleDragOverthired}
          onDrop={handleDropthired}
        >
          {imagePreviewthired ? (
            <img
              src={imagePreviewthired}
              alt="Preview"
              className="w-40 h-40 rounded-lg mb-4"
            />
          ) : (
            <div className="bg-white border border-gray-300 w-50 h-60 rounded-lg flex flex-col items-center justify-center mb-6">
              <p className="text-black font-bold ml-2 mt-2 text-[24px]">
                Prepared By
              </p>
              <img
                src={cloudIcon}
                alt="Cloud Icon"
                className="w-16 h-16 mb-2"
              />
              <p className="text-[#8691AB] font-bold ml-4">
                Drag and Drop Digital Signature
              </p>
            </div>
          )}
        </div> */}
      </div>
      <div className="w-full flex items-center justify-end gap-x-6 my-10 px-16">
        <button
          className="text-white font-bold text-2xl py-2 px-4 rounded-lg bg-[#AA4343]"
          onClick={handleReset}
        >
          Cancel
        </button>
        <button
          onClick={handleSubmit}
          className="bg-[#5B6D49] hover:bg-[#495e33] text-white font-bold text-2xl py-2 px-4 rounded-lg"
        >
          Create
        </button>
      </div>
    </div>
  );
};

export default PerRequestForm;